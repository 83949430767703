<template>
  <div class="list-view product-checkout">

    <!-- Left Card -->
    <b-card no-body>
      <b-card-header class="flex-column align-items-start">
        <b-card-title>Validation</b-card-title>
        <b-card-text class="text-muted mt-25">
          Assurer vous que le récapitulatif respecte votre planification
        </b-card-text>
      </b-card-header>
    </b-card>

    <!-- Right Card -->
    <div class="amount-payable checkout-options">
      <b-card title="Détails de planification">

        <ul class="list-unstyled price-details">
          <li class="price-detail">
            <div class="details-title">
              Nbr.
            </div>
            <div class="detail-amt">
              <strong>4 Produits</strong>
            </div>
          </li>
          <li class="price-detail">
            <div class="details-title">
              Delivery Charges
            </div>
            <div class="detail-amt discount-amt text-success">
              Free
            </div>
          </li>
        </ul>
        <hr>
        <div>
          <b-button
              variant="primary"
              class="mb-1"
            >
              Enregistrer
            </b-button>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BFormGroup, BFormRadio, BFormInput, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    // BSV
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BButton,
  },
  props: {
    paymentDetails: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
#cvv {
  width: auto;
}
</style>
